<template>
   <div class="other-right">
      <div class="others keepCenter">
   <h1 class="contact-title">联系我们</h1>
   <ul class="other-contact clearfix">
      <li>
         <h1>报名咨询</h1>
         <h2>黄同学</h2>
         <p>
            QQ：397498053<br>
         </p>
      </li>


   </ul>

</div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
