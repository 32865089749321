<template>
  <div class="other-right">
    <div class="other-right-wrap">
      <div id="pjax-tiper" class="alert" style="display: none">正在加载...</div>
      <div id="pjax-container">
        <div class="others keepCenter">
          <h1>人才招聘</h1>
          <div class="space-side">
            <ul class="perface">
              <li>
                亲，很高兴在这里与你相遇，能在这里找到我，说明你对小慕足够喜爱足够上心，多谢你的厚爱！
              </li>
              <li>
                小慕现在拿出最大诚意急寻合作战友。自上线以来，p8课堂受到广大程序员的各种喜爱，小慕除内心窃喜以外，深知自己肩上责任重大，<br />只有做出更好的课程，拿出更好的产品体验，才能对得起各位猿猿同学的喜爱。
              </li>
              <li>
                小慕虽不是富二代，但家底也算殷实，来到p8课堂，我们绝不会亏待大家，不低于bat的待遇、五险一金、免费零食这些基本保障，大家都能享受到。<br />不过你这么有追求，相信你更看重我们年轻的活力，积极向上的精气神，以及想做一番事业的决心和勇气。
              </li>
              <li>寻找气息相投的小伙伴，一起mooc，一起改变中国教育！</li>
            </ul>
            <ul class="clearfix job-nav">
              <li><a href="#pm-center">产品中心</a></li>
              <li><a href="#dev-center">研发中心</a></li>
              <li><a href="#operate-center">运营中心</a></li>
              <li><a href="#content-center">内容中心</a></li>
            </ul>

            <div id="pm-center" class="works-wrap">
              <h2>产品中心</h2>
              <div class="job-block">
                <h3>社区产品经理</h3>
                <ol>
                  <li>
                    -&nbsp;负责带领团队对用户需求、市场需求和业务需求进行调研分析，不断优化产品，提升产品质量，提高用户活跃度；
                  </li>
                  <li>
                    -&nbsp;负责后台及社区产品的规划设计，负责项目的执行和效果评估以及后续改进工作；
                  </li>
                  <li>
                    -&nbsp;带领团队完成产品策划工作，包括MRD、PRD撰写及部分交互设计工作；
                  </li>
                  <li>
                    -&nbsp;协助开发、测试团队理解和掌握需求，对产品需求方向和易用性负责；
                  </li>
                  <li>
                    -&nbsp;负责相关产品开发项目周期和进度把控，并对产品开发直至上线运营及项目完成进度和质量负责；
                  </li>
                  <li>
                    -&nbsp;负责跟踪产品上线后的产品运营，根据客户反馈进行产品优化改进；
                  </li>
                  <li>
                    -&nbsp;关注业界动态，不断优化用户体验，及时制定产品的应变方案。
                  </li>
                </ol>
                <h4>职位要求:</h4>
                <ol>
                  <li>-&nbsp;3年以上千万级用户产品经验；</li>
                  <li>
                    -&nbsp;有从原型设计到上线、运营的经验，曾独立负责过后台、广告、社区等产品原型设计和用户运营工作；
                  </li>
                  <li>
                    -&nbsp;熟悉WEB、WAP及HTML5产品设计规范，具备优秀的UE/UI的设计意识和表达能力；
                  </li>
                  <li>
                    -&nbsp;热爱互联网，关注用户体验，对新鲜事物敏感，对新型产品有自己的见解；
                  </li>
                  <li>
                    -&nbsp;具备较强的文字表现能力、PPT文案撰写能力、口头表达能力和学习能力；
                  </li>
                  <li>-&nbsp;善用数据分析，收集用户意见反馈，持续优化产品；</li>
                  <li>
                    -&nbsp;熟练运用Axure、VISIO、Mindmanager、PPT等办公软件。
                  </li>
                </ol>
                <p class="job-email">简历请发送到:wubinhua@imooc.com</p>
              </div>
            </div>
            <div id="dev-center" class="works-wrap">
              <h2>研发中心</h2>
              <div class="job-block">
                <h3>PHP开发工程师</h3>
                <ol>
                  <li>-&nbsp;设计规划教育社区视频类网站系统架构；</li>
                  <li>-&nbsp;维护优化网站；</li>
                  <li>-&nbsp;核心应用或者模块实现；</li>
                  <li>-&nbsp;对其它工程师进行技术指导。</li>
                </ol>
                <h4>职位要求:</h4>
                <ol>
                  <li>
                    -&nbsp;熟悉linux，至少熟悉一种shell脚本语言(bash/perl/python等)；
                  </li>
                  <li>-&nbsp;熟悉nginx/lighttpd/apache之一的配置和优化；</li>
                  <li>
                    -&nbsp;熟悉mysql/postgresql/sqlserver数据库之一的开发与使用，熟悉memcached、redis等的使用；
                  </li>
                  <li>-&nbsp;大专以上学历，3年以上php开发经验；</li>
                  <li>-&nbsp;有大访问量网站开发、规划经验优先；</li>
                  <li>-&nbsp;有视频播放、流媒体类网站研发维护经验优先；</li>
                  <li>-&nbsp;有linux环境下c/c++/java开发经验优先；</li>
                  <li>
                    -&nbsp;有云计算（docker等）部署、配置、开发经验者优先；
                  </li>
                  <li>
                    -&nbsp;工作热情、积极，易于沟通，能迅速融入团队，有一定抗压能力。
                  </li>
                </ol>
                <p class="job-email">简历请发送到:wubinhua@imooc.com</p>
              </div>
              <div class="job-block">
                <h3>Web前端开发工程师</h3>
                <ol>
                  <li>
                    -&nbsp;负责教育社区类网站的前端框架、核心模块设计和实现；
                  </li>
                  <li>-&nbsp;持续优化前端界面和脚本，保证兼容性和高性能；</li>
                  <li>-&nbsp;为团队内其它成员提供培训和指导。</li>
                </ol>
                <h4>职位要求:</h4>
                <ol>
                  <li>-&nbsp;3年以上互联网前端开发工作经验；</li>
                  <li>-&nbsp;熟悉html/js/css/html5/flash等开发；</li>
                  <li>
                    -&nbsp;熟悉常用的1-2种ajax技术及框架（jquery/moontools/extjs/nodejs等）；
                  </li>
                  <li>-&nbsp;熟悉原生JS编写；</li>
                  <li>-&nbsp;熟悉浏览器兼容技术、前端性能优化；</li>
                  <li>-&nbsp;良好的沟通能力和团队协作能力；</li>
                  <li>-&nbsp;善于学习，自我驱动，了解和学习业界新技术。</li>
                </ol>
                <p class="job-email">简历请发送到:wubinhua@imooc.com</p>
              </div>
              <div class="job-block">
                <h3>iOS开发工程师</h3>
                <ol>
                  <li>
                    -&nbsp;负责创新型社区、视频类的Phone/iPad客户端应用的设计开发；；
                  </li>
                  <li>
                    -&nbsp;编写关键模块的设计文档，独立完成编码及单元测试；
                  </li>
                  <li>-&nbsp;主动发现产品不足，并不断地进行维护优化；</li>
                  <li>
                    -&nbsp;手机软件开发技术方面的评估以及可行性方面的分析和探索；
                  </li>
                  <li>-&nbsp;与团队成员充分、有效沟通协作；</li>
                  <li>-&nbsp;为团队内其它成员提供技术指导。</li>
                </ol>
                <h4>职位要求:</h4>
                <ol>
                  <li>-&nbsp;大学本科以上学历，计算机相关专业；</li>
                  <li>-&nbsp;有2年以上手机端app实际开发经验（iOS平台）；</li>
                  <li>
                    -&nbsp;熟悉掌握Objective-C、C++编程语言以及Swift编程协议；
                  </li>
                  <li>
                    -&nbsp;熟悉ios ui、网络、数据库、xml/json解析等开发技巧；
                  </li>
                  <li>-&nbsp;熟悉ios SDK及相关开发、调试、优化工具；</li>
                  <li>-&nbsp;有同一产品跨iPhone/iPad开发经验的优先；</li>
                  <li>-&nbsp;有在app Store发布过作品的优先；</li>
                  <li>
                    -&nbsp;有视频/sns/社区/LBS等移动互联网产品开发经验者优先；
                  </li>
                  <li>
                    -&nbsp;尤其是有自定义播放器、文件下载、多图片加载优化、自定义复杂ui控件等一项或多项经验优先。
                  </li>
                </ol>
                <p class="job-email">简历请发送到:wubinhua@imooc.com</p>
              </div>
            </div>
            <div id="operate-center" class="works-wrap">
              <h2>运营中心</h2>
              <div class="job-block">
                <h3>文案策划</h3>
                <ol>
                  <li>-&nbsp;教育培训类产品包装文案策划；</li>
                  <li>-&nbsp;广告创意策划及文案撰写；</li>
                  <li>-&nbsp;协助团队共同完成相关推广计划的实施；</li>
                  <li>
                    -&nbsp;具有扎实的文字功底，能够独立完成品牌故事、广告文案、各类软文、产品宣传推广方案等各类宣传文案的策划和撰写；
                  </li>
                  <li>
                    -&nbsp;具有良好的沟通能力、执行能力、协调能力和团队合作精神。
                  </li>
                </ol>
                <h4>职位要求:</h4>
                <ol>
                  <li>
                    -&nbsp;广告、新闻媒体、中文等相关专业本科以上学历，3年以上文案撰稿工作经验（教育、广告和媒体行业经验者优先）；
                  </li>
                  <li>-&nbsp;熟悉各类新闻稿件的撰写规范；</li>
                  <li>-&nbsp;有大型活动、网络营销成功案例者优先考虑；</li>
                  <li>
                    -&nbsp;热爱创意和写作，善于发现，并以独到的文笔加以描述、包装；
                  </li>
                  <li>
                    -&nbsp;性格开朗、勤奋、具有良好的团队合作精神，具有较强的抗压能力；
                  </li>
                  <li>-&nbsp;面试时请自带案例作品。</li>
                </ol>
                <p class="job-email">简历请发送到:wubinhua@imooc.com</p>
              </div>
              <div class="job-block">
                <h3>活动策划</h3>
                <ol>
                  <li>
                    -&nbsp;根据p8课堂的产品定位和市场需求，制定相应的线上活动的活动营销方案；
                  </li>
                  <li>
                    -&nbsp;策划p8课堂的各类网络活动方案、创意点、主题营销、口碑营销、视频营销等活动方案；
                  </li>
                  <li>
                    -&nbsp;组织、监督、跟踪执行各类活动的推广执行，实现营运指标；
                  </li>
                  <li>
                    -&nbsp;跟踪、分析各种推广活动的实施效果，并及时提出合理化调整；
                  </li>
                  <li>-&nbsp;分析、检测竞争对手活动。</li>
                </ol>
                <h4>职位要求:</h4>
                <ol>
                  <li>
                    -&nbsp;有2年以上策划经验，熟悉活动的运营和各类活动的策划技巧；
                  </li>
                  <li>
                    -&nbsp;拥有较强的选题策划能力和较强的市场分析判断能力；
                  </li>
                  <li>-&nbsp;注重细节，具有较强的执行力和应变力；</li>
                  <li>
                    -&nbsp;具有相关口碑营销、主题营销、视频营销活动策划成功案例者优先；
                  </li>
                  <li>-&nbsp;面试时请携带相关成功案例。</li>
                </ol>
                <p class="job-email">简历请发送到:wubinhua@imooc.com</p>
              </div>
            </div>
            <div id="content-center" class="works-wrap">
              <h2>内容中心</h2>
              <div class="job-block">
                <h3>讲师(PHP、Java、Web前端、Android、iOS等方向)</h3>
                <ol>
                  <li>
                    -&nbsp;根据用户的学习需求，设计开发更具有前瞻性与实用性的课程；完成课程一体化设计方案，包括教学内容、教学大纲、教学指南、教学活动等；
                  </li>
                  <li>-&nbsp;按照教学计划要求，完成在线课程录制，优化改进；</li>
                  <li>
                    -&nbsp;完善培训教辅材料、案例体系，制定课程考核标准，并建立题库；
                  </li>
                  <li>
                    -&nbsp;指导助教开展网上学习辅导活动，帮助助教提高专业教学能力。
                  </li>
                </ol>
                <h4>职位要求:</h4>
                <ol>
                  <li>
                    -&nbsp;大专科以上学历，2年以上相关方向研发经验，热爱教育行业、热爱p8课堂，有从研发转向教育方向的成熟想法，并立志于教育行业。或者具有2年以上相关方向研发经验，具有1年以上讲师经验者优先。
                  </li>
                  <li>
                    -&nbsp;语言表达流利，思路清晰，有独特的授课风格及表现力，注重理论联系实际，深入浅出；
                  </li>
                  <li>
                    -&nbsp;能独立完成教学资料的开发（题库、案例库）；具有较高的技术水平和丰富的项目开发经验;
                    懂在线学习用户心理，善于沟通；
                  </li>
                  <li>
                    -&nbsp;擅于学习，工作态度积极主动，对学员有极强的责任心；具有创新的思维和意识；具有一定的抗压能力；
                  </li>
                  <li>
                    -&nbsp;具有一定的文字功底，熟练使用Mindmanager、PPT等办公软件；
                  </li>
                </ol>
                <p class="job-email">简历请发送到:wubinhua@imooc.com</p>
              </div>

              <div class="job-block">
                <h3>后期制作(全职、兼职、实习生)</h3>
                <ol>
                  <li>
                    -&nbsp;负责品牌及产品的影视宣传片的创意设计，制作包装；
                  </li>
                  <li>
                    -&nbsp;对于课程的内容呈现方式提出创意性提案，并负责课程视频的后期制作等工作，为用户提供良好的学习体验；
                  </li>
                  <li>-&nbsp;负责对外活动的现场拍摄，及后期剪辑、包装工作；</li>
                  <li>-&nbsp;负责课程的精剪工作。</li>
                </ol>
                <h4>职位要求</h4>
                <ol>
                  <li>-&nbsp;大专以上学历，1年或以上行业工作经验；</li>
                  <li>
                    -&nbsp;精通After
                    Effects、Premiere等后期制作软件，了解后期各种软件协同工作的流程，熟练掌握音频软件、三维后期合成、跟踪软件、各类插件的使用方法；
                  </li>
                  <li>
                    -&nbsp;熟练使用各类Script，表达式运用熟练，能解决各种技术问题；
                  </li>
                  <li>-&nbsp;熟练操作PS、AI等辅助软件；</li>
                  <li>-&nbsp;能够严谨的管理项目文件，擅长与团队协作；</li>
                  <li>
                    -&nbsp;热爱动画，掌握动画原理，熟练使用各种动画技巧，能很好的把控动画整体节奏及运动规律；
                  </li>
                  <li>
                    -&nbsp;具备良好的镜头感，能够与团队协同完成高质量的动画作品。
                  </li>
                  <li>备注:带作品面试，待遇优厚具体看能力面谈！</li>
                </ol>
                <p class="job-email">简历请发送到:wubinhua@imooc.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style>
.keepCenter {
  background-color: #fff;
}
</style>
