<template>
  <div class="other-right">
    <div class="other-right-wrap">
      <div id="pjax-tiper" class="alert" style="display: none">正在加载...</div>
      <div id="pjax-container">
        <div class="group">
          <div class="group-hd" style="position: relative">
            <img
              class="group-img keepCenter"
              src="https://www.imooc.com/static/img/aboutus/g1.jpg"
            />
          </div>
          <div class="group-bd clearfix">
            <ol class="clearfix">
              <li class="keepCenter">
                <div class="text-box l">
                  <h3>FOCUS</h3>
                  <p>每天专注的做着一件很牛很酷的事儿</p>
                </div>
                <img
                  class="img-box l"
                  src="https://www.imooc.com/static/img/aboutus/g2.jpg"
                />
              </li>
              <li class="l keepCenter">
                <img
                  class="img-box l"
                  src="https://www.imooc.com/static/img/aboutus/g3.jpg"
                />
                <div class="text-box l">
                  <h3>HAPPY</h3>
                  <p>在这里拥有的快乐远比你想象的多</p>
                </div>
              </li>
              <li class="l keepCenter">
                <img
                  class="img-box l"
                  src="https://www.imooc.com/static/img/aboutus/g4.jpg"
                />
              </li>
              <li class="l keepCenter">
                <div class="text-box l">
                  <h3>GREEN</h3>
                  <p>接地气，与绿色植物共同成长</p>
                </div>
                <img
                  class="img-box l"
                  src="https://www.imooc.com/static/img/aboutus/g5.jpg"
                />
              </li>
              <li class="l keepCenter">
                <img
                  class="img-box l"
                  src="https://www.imooc.com/static/img/aboutus/g6.jpg"
                />
                <div class="text-box l">
                  <h3>LEARNING</h3>
                  <p>不断的吸收，是为了不停的创新</p>
                </div>
              </li>
              <li class="l keepCenter">
                <div class="text-box l">
                  <h3>SHARE</h3>
                  <p>分享是一种思想的深度</p>
                  <p>甚至可以体现在炸鸡上</p>
                </div>
                <img
                  class="img-box l"
                  src="https://www.imooc.com/static/img/aboutus/g7.jpg"
                />
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
  .keepCenter {
            margin-left: 66px;
          }
</style>