<template>
  <div class="other-right">
        <div class="other-right-wrap">
          <div id="pjax-tiper" class="alert" style="display: none">
            正在加载...
          </div>
          <div id="pjax-container">
            <div class="aboutUs-header">
              <div class="header-title-box">
                <div class="header-title-icon"></div>
                <div class="header-title">p8课堂是谁</div>
              </div>
              <h3>
                我们是IT教育行业的<i>造梦者</i>，也是前沿技术内容的<i>创造者</i>和<i>传播者</i>！
              </h3>
              <div class="header-content">
                作为国内深受欢迎的互联网IT技能学习网站，p8课堂自2013年成立至今，始终专注IT在线教育，以培养互联网企业实用型人才为<br />
                己任，邀请一线大厂技术达人打造前沿的IT技术精品课程，赋能全球范围内每一位有梦想、有志向的开发者实现职业梦想。
              </div>
            </div>
            <div class="aboutUs-body-data"></div>
            <div class="aboutUs-body-main">
              <div class="header-title-box">
                <div class="header-title-icon"></div>
                <div class="header-title">p8课堂的宝藏内容</div>
              </div>
              <div class="main-hover-huge">
                <div class="hover-img hover-img1 js-hover"></div>
                <div class="hover-img hover-img2 js-hover"></div>
                <div class="hover-img hover-img3 js-hover"></div>
              </div>
              <div class="aboutUs-body-content">
                课程范围覆盖：前端 \JAVA \Python \Go
                \人工智能\大数据\移动端等60类主流技术语言，充分满足了面试就业、职业成长、自<br />我提升等实际需求，帮助用户实现从技能提升到岗位提升的能力闭环。
              </div>


<!--
              <div class="header-title-box header-title-box2">
                <div class="header-title-icon"></div>
                <div class="header-title">为什么选择p8课堂</div>
              </div>-->


              <!--<div class="aboutUs-body-reason"></div>
              <div class="header-title-box header-title-box3">
                <div class="header-title-icon"></div>
                <div class="header-title">p8课堂的高光时刻</div>
              </div>
              <div class="aboutUs-body-showtime">
                <div class="showtime-top">
                  <div class="left-year">
                    <div class="p-title">【权威媒体评选教育行业荣誉奖项】</div>
                    <div class="time-line"></div>
                    <div class="time time1">
                      <span>2014</span>
                      <i>年</i>入选新浪网“最具口碑影响力在线教育机构”
                    </div>
                    <div class="time time2">
                      <span>2014</span>
                      <i>年</i>入选新浪网“最具投资价值教育APP产品”
                    </div>
                    <div class="time time3">
                      <span>2015</span>
                      <i>年</i>入选腾讯网“2015年度影响力在线教育品牌”
                    </div>
                    <div class="time time4">
                      <span>2015</span>
                      <i>年</i
                      >获百度站长平台首届新站评选大赛“垂直行业最佳新站”奖项
                    </div>
                    <div class="time time5">
                      <span>2019</span>
                      <i>年</i>入选拉勾网“2019中国互联网潜力雇主”奖项
                    </div>
                  </div>
                  <div class="right-prize">
                    <div class="award-l">
                      <div class="award award1"></div>
                      <div class="award award2"></div>
                      <div class="award award3"></div>
                    </div>
                    <div class="award-r">
                      <div class="award award4"></div>
                      <div class="award award5"></div>
                    </div>
                  </div>
                </div>
                <div class="showtime-bottom">
                  <div class="left-year">
                    <div class="p-title">
                      【苹果App Store推荐榜首的教育类应用】
                    </div>
                    <div class="time-line"></div>
                    <div class="time time1">
                      <span>2014.04</span>p8课堂APP荣登App
                      Store精品推荐“总得写代码”榜单榜首
                    </div>
                    <div class="time time2">
                      <span>2014.05</span>p8课堂APP荣登App
                      Store精品推荐“优秀新App榜首”
                    </div>
                    <div class="time time3">
                      <span>2015.01</span>入选App Store “精选教育App” 榜单
                    </div>
                    <div class="time time4">
                      <span>2019.01</span>荣登App Store“学点新东西”专题首位推荐
                    </div>
                    <div class="time time5">
                      <span>2021.09</span>入选App Store“人人能编程”专题推荐
                    </div>
                  </div>-->
                  <div class="right-prize">
                    <div class="award award6"></div>
                    <div class="award award7"></div>
                    <div class="award award8"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
