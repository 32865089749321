<template>
  <div id="main">
    <div class="aboutUs-img"></div>
    <div class="container">
      <div class="header-tob">
        <ul class="other-left">
            <!--  -->
          <li v-for="(v,i) in list" :key="i" @click="active=v"  :class="{'selected':active==v}">
            <a href="#"><span>{{v}}</span></a>
          </li>
        </ul>
      </div>
      <item1 v-show="active=='关于我们'"/>
     <!-- <item2 v-show="active=='团队介绍'"/>
      <item3 v-show="active=='人才招聘'"/>-->
      <item4 v-show="active=='联系我们'"/>
    </div>
  </div>
</template>

<script>
import item1 from './contact/item1.vue'
import item2 from './contact/item2.vue'
import item3 from './contact/item3.vue'
import item4 from './contact/item4.vue'
export default {
   data(){
       return{
           active:"关于我们",
           list:["关于我们","团队介绍","人才招聘","联系我们"]
       }
   },
   components:{
       item1,
       item2,
       item3,
       item4,
   },
   created(){
       if(this.$route.query.id=='lxwm'){
           this.active = '联系我们'
       }
       console.log()
   }
};
</script>

<style>
@import url('../../assets/css/content.css');
</style>
